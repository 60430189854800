<!-- 回复弹框 -->
<template>
  <div class="_ciji">
    <div class="bjq">
      <div class="flex1 fwb">
        <wangEditor
          v-model="fuwenben"
          :key="cishu"
          :value="fwb"
          ref="editor"
        ></wangEditor>
      </div>
      <div class="btn">
        <el-button type="primary" @click="pinglun">发表</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import wangEditor from "./wangEditor/index.vue";
import API from "@/api/modules/details.js";
var timess;
export default {
  components: {
    wangEditor,
  },
  data() {
    return {
      cishu: 0, //富文本清空
      bjq: "", // 编辑器内容
      fuwenben: "",
      fwb: "",
      benstu: 0,
      cipinsuo: false,
      loading: null,
    };
  },
  mounted() {
    sessionStorage.setItem("cistu", 0);
    this.benditan();
  },
  methods: {
    // 插入内容
    chaone(url) {
      this.$refs.editor.charutu(url);
    },
    // 监听本地存储,最蠢的方法,我暂时没有更好的想法(2023/6/10)
    benditan() {
      // 将它异步
      let that = this;
      timess = setTimeout(() => {
        that.benstu = sessionStorage.getItem("cistu");
        if (that.benstu == 0) {
          that.benditan();
        } else {
          this.$parent.cipingtan();
        }
      }, 100);
    },
    // 判断是否为64
    isBase64(str) {
      if (str.indexOf("data:") != -1 && str.indexOf("base64") != -1) {
        return true;
      } else {
        return false;
      }
    },
    baseti() {
      let cuo = 0;
      let a = this.$refs.editor.GetTheHTML();
      // 匹配所有图片标签,获得地址
      let imgStrs = a.match(/<img.*?>/g);
      if (imgStrs != null) {
        let urls = imgStrs.map((url) => {
          return url.match(/\ssrc=['"](.*?)['"]/)[1];
        });
        urls.forEach((item) => {
          // 将base64图片选出来
          if (this.isBase64(item) == true) {
            this.$alert(
              "您的图片存在格式问题,请删除后通过上传图片功能重新插入!",
              "警告",
              {
                confirmButtonText: "确定",
                callback: (action) => {},
              }
            );
            cuo = 1;
          }
        });
      }
      if (cuo == 0) {
        return false;
      } else {
        return true;
      }
    },
    async pinglun() {
      // base64提示
      let cuo = this.baseti();
      if (cuo == true) {
        return;
      }
      this.loading = this.$loading({
        lock: true,
        // text: "上传中...",
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.0)",
      });


      
      // 发送前先校验
      let res = await API.sensitive({
        info: this.$refs.editor.GetTheHTML(),
      }).then((res) => {
        if (res.code == 1) {
          let str = this.$refs.editor.GetTheHTML();
          // var reg = new RegExp("<[^>]+>", "gi"); //过滤所有的html标签，不包括内容
          // let a = str.replace(reg, "");
          // let value = a.replace(/&nbsp;/gi, ""); //过略所有nbsp
          // let b = value.replace(/\s*/g, ""); // 过略字符窜前后空格
          let aa = this.kongwu(str)
          if (aa == false) {
            this.loading.close();
            this.$message({
              message: "请输入内容!",
              type: "warning",
            });
            
            return;
          }
          this.cipinsuo = this.$parent.ciping(str);
        } else {
          this.loading.close();
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
     // 评论空校验
     kongwu(htmlstr) {
      // 创建一个新的DOM元素，例如一个div
      const tempDiv = document.createElement("div");

      // 设置div的内容为所提供的HTML
      tempDiv.innerHTML = htmlstr;

      // 检查div中是否有文字内容
      const hasText = tempDiv.textContent.trim().length > 0;

      // 检查div中是否有图片
      const hasImage = tempDiv.querySelector("img") !== null;

      // 返回是否有文字或图片的结果
      return hasText || hasImage;
    },
    //清空富文本
    qk() {
      this.cishu = this.cishu + 1;
    },
  },
  // 销毁定时器
  beforeDestroy() {
    clearTimeout(timess);
    // 次级评论销毁时打开回复帖子的图片上传弹框监视
    this.$parent.guantan();
  },
};
</script>

<style scoped lang="scss">
._ciji {
  width: 100%;
  height: calc(100% - 36px);
}

.bjq {
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.btn {
  margin-top: 8px;

  ::v-deep(.el-button--primary) {
    background: #1c69c6;
    border-radius: 2px;
    width: 100px;
    height: 34px;
    border: 0px solid red;
  }
}
</style>