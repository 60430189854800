// 引入 wangEditor
import E from 'wangeditor' // npm 安装
import editor from 'wangeditor'
// const E = window.wangEditor // CDN 引入的方式
import store from '@/store'


// 获取必要的变量，这些在下文中都会用到
const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E

// 【注意】如果版本 <= v4.0.5 需要这样写：
// const { $ } = E
// const { BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E.menuConstructors

export default class Head extends DropListMenu {
  constructor(editor) {
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = $('<div class="w-e-menu" data-title="单位">@</div>')

    let a = []
    store.state.buarr.forEach(item => {
      a.push({
        $elem: $(`<div style="font-szie:18px;">${item.name}</div>`), 'value': item.name
      })
    })

    // [
    //   { $elem: $('<h1>H1</h1>'), value: '<h1>' },
    //   { $elem: $('<h2>H2</h2>'), value: '<h2>' },
    //   { $elem: $('<h3>H3</h3>'), value: '<h3>' },
    //   { $elem: $('<h4>H4</h4>'), value: '<h4>' },
    //   { $elem: $('<h5>H5</h5>'), value: '<h5>' },
    //   { $elem: $('<p>正文</p>'), value: '<p>' },
    // ]
    // droplist 配置
    let dropListConf = {
      width: 100,
      title: '选择部门',
      type: 'list',
      list: a,
      // droplist 每个 item 的点击事件
      clickHandler: (value) => {
        // value 参数即 dropListConf.list 中配置的 value
        this.command(value)
      },
    }
    super($elem, editor, dropListConf)
  }

  command(value) {
    // 先获取内容
    let a = this.editor.txt.html()
    // 清空内容
    this.editor.txt.html(`<span></span>`)
    // 追加内容
    this.editor.txt.append(`<span style='color:#339933;margin-right:10px'>@${value}</span>`)
    // 还原内容
    this.editor.txt.append(a)
  
    // 设置标题
    // this.editor.cmd.do('formatBlock', value)
  }

  // // 菜单点击事件
  // clickHandler() {
  //   // 做任何你想做的事情
  //   // 可参考【常用 API】文档，来操作编辑器

  // }
  // 菜单是否被激活（如果不需要，这个函数可以空着）
  // 1. 激活是什么？光标放在一段加粗、下划线的文本时，菜单栏里的 B 和 U 被激活，如下图
  // 2. 什么时候执行这个函数？每次编辑器区域的选区变化（如鼠标操作、键盘操作等），都会触发各个菜单的 tryChangeActive 函数，重新计算菜单的激活状态
  tryChangeActive() {
    const reg = /^h/i
    const cmdValue = this.editor.cmd.queryCommandValue('formatBlock')
    if (reg.test(cmdValue)) {
      // 选区处于标题内，激活菜单
      this.active()
    } else {
      // 否则，取消激活
      this.unActive()
    }
  }
}

