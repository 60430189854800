<!-- 播放弹框 -->
<template>
  <div class="_bofang">
    <div class="tupiao" @click="close">
      <i class="el-icon-circle-close"></i>
    </div>
    <div class="capain">
      <Videoplayer :mp4Url="url"></Videoplayer>
    </div>


  </div>
</template>
  
  <script>
import Videoplayer from "../video/index.vue"; // 视频播放组件
export default {
  components: {
    Videoplayer,
  },
  props: {
    url: String,
  },
  data() {
    return {
      surl: "",
    };
  },
  mounted() {
    // console.log(this.url, "  ");
  },
  methods: {
    close() {
      this.$parent.shiclose();
     
    },
  },
};
</script>
  
<style scoped lang="scss">
._bofang {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 100001;
}
.tupiao {
  position: fixed;
  right: 30px;
  top: 30px;
  cursor: pointer;
  ::v-deep(.el-icon-circle-close) {
    font-size: 30px;
    color: #fff;
  }
}
.capain {
  width: 60%;
  height: 60%;
}
</style>