// 帖子详情
import request from '../request'

export default class Details {
    /**
* 上传接口
* @param {*} params
* @returns
*/
    static upload(data) {
        return request.post('/api/common/upload', data)
    }
    /**
* 帖子详情
* @param {*} params 
* @returns 
*/
    static articleInfo(params) {
        return request.get("/api/v1.articlecurrency/articleInfo", {
            params
        })
    }
    /**
* 发布评论
* @param {*} params
* @returns
*/
    static artComment(data) {
        return request.post('/api/v1.articlecomment/artComment', data)
    }
    /**
* 评论列表
* @param {*} params 
* @returns 
*/
    static artCommetList(params) {
        return request.get("/api/v1.articlecomment/artCommetList", {
            params
        })
    }
    /**
     * 评论列表(小程序)
     * @param {*} params 
     * @returns 
     */
    static artCommetListwechat(params) {
        return request.get("/api/v1/articlecomment/artCommetListwechat", { params })
    }
    /**
* 关注操作
* @param {*} params 
* @returns 
*/
    static useFollow(params) {
        return request.get("/api/v1.usercontrol/useFollow", {
            params
        })
    }
    /**
* 收藏点赞
* @param {*} params
* @returns
*/
    static artileCollect(data) {
        return request.post('/api/v1.usercontrol/artileCollect', data)
    }
    /**
* 举报操作
* @param {*} params
* @returns
*/
    static articleRepot(data) {
        return request.post('/api/v1/usercontrol/articleRepot', data)
    }
    /**
* 敏感词查询
* @param {*} params 
* @returns 
*/
    static sensitive(params) {
        return request.get("/api/v1.articlecurrency/sensitive", {
            params
        })
    }
}
